import React from 'react'
import { Helmet } from 'react-helmet';

import About from '../components/About';
import Hero from '../components/Hero';
import Services from '../components/Services';

const Home = () => {
  return (
    <>
        <Helmet>
          <title>RVP Soft - Bring vision to life.</title>
          <meta name='description' content='Welcome to RVP Soft! We are a team of experienced developers, designers, and project managers dedicated to delivering high-quality digital solutions to our clients. With a focus on innovation and collaboration, we work closely with our clients to understand their unique needs and goals.' />
          <meta name='keywords' content='site, website, automatization, application, host, maintenance, bussines page, developer, web dev agency, creare site, site firma, e-commerce site, magazin online' />
          <meta name="author" content="RVP SOFT S.R.L." />
        </Helmet>
        <Hero />
        <Services />
        <About />
    </>
  )
}

export default Home