export const TRANSLATIONS_RO = {
  herotitle1:"Iți digitalizăm idea",
  herotitleblue:"inovatoare.",
  herotitle2:"",
  herosub:"Bine te-am găsit la RVP Soft! Suntem o echipă de dezvoltatori cu experiență dedicată furnizării de soluții digitale de înaltă calitate clienților noștri. Concentrându-ne pe inovație și colaborare, lucrăm îndeaproape cu clienții noștri pentru a înțelege nevoile și obiectivele lor unice. Misiunea noastră este să dăm putere afacerilor și organizațiilor cu tehnologie de ultimă oră, experiențe excepționale pentru utilizatori și asistență de neegalat. Indiferent dacă aveți nevoie de un site web simplu, de o aplicație web complexă sau de o soluție software personalizată, suntem aici pentru a vă ajuta să vă aduceți viziunea la viață.",
  herobutt:"VEZI SERVICIILE NOASTRE",
  service1:"Ai o afacere inteligentă, dar site-ul tău nu arată suficient de inteligent? Va proiectăm un site web care să vă convertească și să vă dezvolte afacerea.",
  service2:"Proiectam și dezvoltam orice aplicație pentru dumneavoastra pe baza proceselor și cerințelor afacerii pe care o administrați.",
  service3:"Echipa noastră de dezvoltatori web are expertiza tehnică de care aveți nevoie pentru întreținerea serverului și o alegere bună de găzduire.",
  servicebutt:"AFLĂ MAI MULTE",
  service:"Servicii",
  tech1:"Tehnologii cu care lucrăm",
  tech2:"și multe altele...",
  about1:"Dă viață",
  aboutblue:"viziunii",
  about2:"tale!",
  aboutsub:"Proiectăm și dezvoltăm site-uri web și aplicații la cererea ta. Dezvoltatorii noștri pot gestiona întregul proces de dezvoltare a paginii web/aplicației pentru compania dumneavoastră. De la proiectare, până la dezvoltare, testare sau dacă aveți deja un site web sau o aplicație, vă putem oferi suport și întreținere pentru a vă simplifica procesele interne.",
  aboutbutt:"ÎNCEPE ACUM",
  contacttitle:"Contacteaza-ne",
  contactsub:"Întrebări sau observații? Scrie-ne și îți vom răspunde cât mai repede posibil!",
  cinfo:"Informații",
  formback:"Completează formularul și echipa noastră te va contacta înapoi în maxim 24 de ore.",
  fname:"Prenume",
  lname:"Nume",
  phone:"Telefon",
  product:"Ce serviciu te-ar interesa?",
  message:"Spune-ne cu ce te putem ajuta..",
  send:"TRIMITE",
  provide:"Ce îți putem oferii:",
  wdt1:"Pagină web de prezentare pentru business-ul tău",
  wdt2:"Site e-commerce (magazin online)",
  wmt:"Aplicație software",
  hmt1:"Găzduire pentru siteul/aplicația ta",
  hmt2:"Mentenanță pentru siteul/aplicația ta",
  maintenance:"Mentenanță și suport rapid.",
  seo:"Optimizare SEO.",
  optimization:"Optimizare pentru toate platformele garantată.",
  unique:"Design unic, construit în consultanță cu tine.",
  free:"Găzduire web | server mail | certificat SSL gratuit.",
  branding:"Interfața intuitivă.",
  dash:"Panou de administrare ușor de folosit.",
  custom:"Foarte editabil si ușor.",
  pay:"Configurare platformă plăți inclusă.",
  fresh:"Folosim tehnologii de ultimă oră (React, React Native, Node JS, SQL, JavaScript, ...).",
  cross:"Suport pentru aplicații mobile (IOS & Android).",
  security:"Securitate avansată.",
  host:"Găzduire web.",
  mail:"Server de mail inclus.",
  ssl:"Certificat SSL gratuit.",
  fast:"Servere foarte rapide.",
  redes:"Redesenăm site-ul sau applicația ta",
  feat:"Adăugăm funcționalități noi de care ai nevoie.",
  scale:"Scalăm aplicația ta.",
  upseo:"Integrare sau upgrade SEO",
  err:"Rezolvăm orice eroare sau bug întâmpini.",
  touch:"Ia legătura cu noi pentru o ofertă dedicată ție",
  hireus:"Cere o ofertă!",
  home:"Acasă",
  serv:"Servicii",
  deploy:"Găzduire & lansare gratuită pentru aplicația ta.",
  serv1:"Design Web",
  serv2:"Aplicații web & mobile",
  serv3:"Mentenanță & Găzduire" 
};