export const TRANSLATIONS_EN = {
  herotitle1:"We make great digital products for",
  herotitleblue:"innovative",
  herotitle2:"brands.",
  herosub:"Welcome to RVP Soft! We are a team of experienced developers, designers, and project managers dedicated to delivering high-quality digital solutions to our clients. With a focus on innovation and collaboration, we work closely with our clients to understand their unique needs and goals, and then leverage our technical expertise to create custom solutions that exceed their expectations. Our mission is to empower businesses and organizations with cutting-edge technology, exceptional user experiences, and unparalleled support. Whether you need a simple website, a complex web application, or a custom software solution, we are here to help bring your vision to life.",
  herobutt:"SEE OUR SERVICES",
  service1:"You have a smart business but your website doesn't look smart enough? Crafting websites that convert and grow your business.",
  service2:"Design and develop such a application for you based on your business processes and requirements.",
  service3:"Our team of web developers has the technical expertise you need for server maintenance and a good hosting choice.",
  servicebutt:"LEARN MORE",
  service:"Services",
  tech1:"Technologies that we work with",
  tech2:"and more...",
  about1:"Bring your",
  aboutblue:"vision",
  about2:"to life!",
  aboutsub:"We design and develop websites and applications that deliver outstanding results. Our web developers can manage the entire web/app development process for your company. From design, to development, testing, or if you already have a website or an application, we can provide you with support and maintenance to simplify your internal processes.",
  aboutbutt:"GET STARTED",
  contacttitle:"Contact Us",
  contactsub:"Any questions or remarks? Just write us a message!",
  cinfo:"Contact Information",
  formback:"Fill up the form and our team will get back to you within 24 hours.",
  fname:"First Name",
  lname:"Last name",
  phone:"Phone",
  product:"What product do you need?",
  message:"Write your message..",
  send:"SEND MESSAGE",
  provide:"We provide for you:",
  wdt1:"Business presentation page",
  wdt2:"E-commerce website",
  wmt:"Software application",
  hmt1:"Hosting for website or application",
  hmt2:"Maintenance web/app",
  maintenance:"Round the clock maintenance and support.",
  seo:"SEO optimized.",
  optimization:"Guaranteed responsiveness and optimization.",
  unique:"Unique design, in consultancy with you.",
  free:"Web hosting | E-mail server | SSL certificate for free.",
  branding:"Fresh branding and intuitive user interface.",
  dash:"A custom easy-to-use dashboard.",
  custom:"Very customizable.",
  pay:"Payments configuration included.",
  fresh:"Fresh technologies (React, React Native, Node JS, SQL, JavaScript, ...).",
  cross:"Supporting mobile apps (IOS & Android).",
  security:"Strict security protocols.",
  host:"Web hosting.",
  mail:"E-mail server included.",
  ssl:"SSL certificate for free.",
  fast:"Very fast and responsive servers.",
  redes:"Re-design you website / application",
  feat:"Adding new features.",
  scale:"Scale up your app.",
  upseo:"Integrating/Upgrading SEO",
  err:"Solve any error or bug.",
  touch:"Get in touch for an offer",
  hireus:"Hire us!",
  home:"Homepage",
  serv:"Services",
  deploy:"Free hosting & deploy your application.",
  serv1:"Web Design",
  serv2:"Web & Mobile Apps",
  serv3:"Maintenance & Host"
};